import React, { useContext } from 'react';
import {
  BenefitDescriptionBox,
  BenefitsSectionTitle,
} from 'src/components/Sites/ServicesPages/shared/Benefits';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import styled from 'styled-components';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import { WebsitesPageContext } from 'src/pages/strony-internetowe';
import FadeLink from '../../../shared/navigations/FadeLink/FadeLink';
import useLng from "../../../../hooks/useLng";

const StyledMobileWrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (min-width: 1700px) {
    margin-top: ${({ moveY }) => moveY && '-70px'};
  }

  @media screen and (max-width: 1199px) {
    margin-top: ${({ moveY }) => moveY && '-50px'};
  }

  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 0;
  }
`;

const StyledDesktopWrapper = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledImageWrapper = styled(ImageWrapper)`
  @media screen and (max-width: 767px) {
    display: block;
    margin: 7rem 0 0;
  }
`;

const StyledTitle = styled(SubTitle)`
  margin-bottom: 7rem;

  @media screen and (max-width: 991px) {
    margin-bottom: 4rem;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  margin-bottom: 0;

  @media screen and (max-width: 1199px) {
    display: block;
    padding: 0;
  }
`;

const OurBenefitsSection = ({data}) => {
  // const { data } = useContext(WebsitesPageContext);
  const { t } = useLng()

  const sliderImages = data?.filter(
    ({ name }) => name === 'polskie instalacje' || name === 'providence',
  );

  return (
    <Section>
      <StyledWrapper mw>
        <StyledInnerWrapper>
          <MoveIn style={{ flexBasis: '50%' }}>
            <Slider onlyDesktop m="7rem auto" slides={sliderImages} />
          </MoveIn>
          <StyledInnerWrapper pl>
            <InnerBox>
              <BenefitDescriptionBox>
                <StyledMobileWrapper>
                  <FadeIn>
                    <BenefitsSectionTitle>
                      {t("benefit")}
                    </BenefitsSectionTitle>
                  </FadeIn>
                </StyledMobileWrapper>
                <FadeIn>
                  <ParagraphInfo smallMedium bold>
                    {t("benefit2")}
                  </ParagraphInfo>
                  <ParagraphInfo smallMedium>
                    {t("benefit3")}
                  </ParagraphInfo>
                </FadeIn>
              </BenefitDescriptionBox>
              <BenefitDescriptionBox>
                <FadeIn>
                  <ParagraphInfo smallMedium bold>
                    {t("benefit4")}
                  </ParagraphInfo>
                  <ParagraphInfo smallMedium>
                    {t("benefit5")}
                  </ParagraphInfo>
                </FadeIn>
              </BenefitDescriptionBox>
            </InnerBox>
          </StyledInnerWrapper>
        </StyledInnerWrapper>
        <StyledInnerWrapper>
          <InnerBox bigPadding>
            <BenefitDescriptionBox>
              <StyledDesktopWrapper>
                <FadeIn>
                  <StyledTitle>{t("benefit6")}</StyledTitle>
                </FadeIn>
              </StyledDesktopWrapper>
              <StyledMobileWrapper>
                <MoveIn style={{ flexBasis: '50%' }}>
                  <Slider m="7rem auto" slides={sliderImages} />
                </MoveIn>
              </StyledMobileWrapper>
              <FadeIn>
                <ParagraphInfo smallMedium bold>
                  {t("benefit7")}
                </ParagraphInfo>
                <ParagraphInfo smallMedium>
                  {t("benefit8")}
                </ParagraphInfo>
              </FadeIn>
            </BenefitDescriptionBox>
            <BenefitDescriptionBox>
              <FadeIn>
                <ParagraphInfo smallMedium bold>
                  {t("benefit9")}
                </ParagraphInfo>
                <ParagraphInfo smallMedium>
                  {t("benefit10")}
                </ParagraphInfo>
              </FadeIn>
            </BenefitDescriptionBox>
          </InnerBox>
          <MoveIn style={{ flexBasis: '50%' }}>
            <StyledInnerWrapper pr>
              <StyledImageWrapper
                image={
                  data?.find(
                    ({ name }) => name === 'strony internetowe pilsudski',
                  ).childImageSharp.fluid
                }
                alt="strony internetowe piłsudski"
              />
            </StyledInnerWrapper>
          </MoveIn>
        </StyledInnerWrapper>
      </StyledWrapper>
    </Section>
  );
};

export default OurBenefitsSection;
