import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import {
  services, servicesEN,
  technologies,
} from 'src/components/Sites/ServicesPages/WebPages/data';
import List from 'src/components/shared/List/List';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import { WebsitesPageContext } from 'src/pages/strony-internetowe';
import ImageWrapper from '../../../shared/ImageWrapper/ImageWrapper';
import FadeLink from '../../../shared/navigations/FadeLink/FadeLink';
import useLng from "../../../../hooks/useLng";
import {useI18next} from "gatsby-plugin-react-i18next";

const StyledParagraphInfo = styled(ParagraphInfo)`
  font-size: ${({ small }) => (small ? '2rem' : '3rem')};
  line-height: 1.5;
  text-align: ${({ center }) => center && 'center'};

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const WhatWeCanDoContentWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  transform: ${({ translateY }) => translateY && 'translateY(-8rem)'};

  @media screen and (max-width: 1199px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  @media screen and (max-width: 767px) {
    display: block;
    transform: translateY(0);
  }
`;

const StyledList = styled(List)`
  margin-right: 24rem;

  @media screen and (max-width: 991px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: 767px) {
    margin-right: 2rem;
    margin-bottom: 7rem;
  }
`;

const OnlyDesktop = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const OnlyMobile = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const HowDoWeWorkSection = ({city, data}) => {
  const {t} = useLng()
  const { language} = useI18next()
  const technologiesImg = data?.find(({ name }) => name === 'technologie');

  return (
    <Section>
      <Wrapper nonGrid>
        <FadeIn>
          <Label>
            {t("how")}
          </Label>
        </FadeIn>
        <FadeIn>
          <StyledParagraphInfo small>
            {t("how2")}
          </StyledParagraphInfo>
        </FadeIn>
      </Wrapper>
      <Wrapper pl nonGrid>
        <FadeIn>
          <SubTitle half>
            {!!city ? t("how3_c", {name: city}) :t("how3")}
          </SubTitle>
        </FadeIn>
      </Wrapper>
      <WhatWeCanDoContentWrapper pl>
        <StyledList items={language === "pl" ? services : servicesEN} />
        <MoveIn style={{ flexBasis: '100%' }} animationToProp={{ y: -40 }}>
          <ImageWrapper
            image={
              data?.find(({ name }) => name === 'na co komu praworzadnosc')
                .childImageSharp.fluid
            }
            width="100%"
            alt="na co komu praworządność"
          />
        </MoveIn>
      </WhatWeCanDoContentWrapper>
      <WhatWeCanDoContentWrapper pr translateY last>
        <MoveIn style={{ flexBasis: '100%' }}>
          <OnlyDesktop>
            <ImageWrapper
              image={technologiesImg?.childImageSharp.fluid ?? ""}
              alt="wykorzysytywane technologie"
            />
          </OnlyDesktop>
        </MoveIn>
        <InnerBox bigPadding>
          <ParagraphInfo title mb="2.2rem">
            <FadeIn>
              {t("how4")}
            </FadeIn>
          </ParagraphInfo>
          <ParagraphInfo title mb="6rem">
            <FadeIn>
              {t("how5")}
            </FadeIn>
          </ParagraphInfo>
          <List items={technologies} />
          <MoveIn style={{ flexBasis: '100%' }}>
            <OnlyMobile>
              <ImageWrapper
                image={technologiesImg?.childImageSharp.fluid ?? ""}
                alt="wykorzysytywane technologie"
              />
            </OnlyMobile>
          </MoveIn>
        </InnerBox>
      </WhatWeCanDoContentWrapper>
    </Section>
  );
};

export default HowDoWeWorkSection;
