import styled from 'styled-components';
import Title from 'src/components/shared/text/Title';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';

export const BenefitDescriptionBox = styled.div`
  margin-bottom: 4rem;
  
  ${ParagraphInfo} {
    margin-bottom: 3rem;
  }
  
  @media screen and (max-width: 767px) {
    ${ParagraphInfo} {
      margin-bottom: 2rem;
    }
  }
`;

export const BenefitsSectionTitle = styled(Title)`
  font-size: 2.2rem;
  line-height: 1.6;
  margin-bottom: 7rem;
  font-weight: 700;
  opacity: 1;
  
  @media screen and (max-width: 767px) {
    margin-bottom: 4rem;
  }
`