import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
  font-size: 6rem;
  font-weight: 700;
  
  @media screen and (max-width: 767px) {
    font-size: 4rem;
  }
`

export default Title;