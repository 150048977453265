export const typesOfWebsites = [
  'strony one-page,',
  'strony multi-page,',
  'rozbudowane strony www,',
  'responsywne strony www,',
  'dedykowane systemy internetowe,',
  'aplikacje webowe,',
  'sklepy internetowe,',
  'fora,',
  'blogi,',
  'oraz wsparcie techniczne i rozbudowa istniejących rozwiązań.',
];
export const typesOfWebsitesEN = [
  'one-page websites,',
  'multi-page websites,',
  'complex websites,',
  'responsive websites,',
  'dedicated web systems,',
  'web applications,',
  'online stores,',
  'forums,',
  'blogs,',
  'as well as technical support and expansion of existing solutions.'
];
export const services = [
  'projekt nazwy,',
  'projekt logo,',
  'dedykowany layout,',
  'wdrożenie frontendu,',
  'podpięcie dedykowanego lub gotowego backendu (CMS),',
  'przygotowanie responsywnej wersji mobilnej,',
  'wdrożenie gotowej strony na serwer,',
  'podstawowa optymalizacja pod kątem wyszukiwarek (SEO),',
  'wsparcie techniczne i podczas rozbudowy,',
  'integracja z dodatkowymi modułami i narzędzami.',
];
export const servicesEN = [
  'name design,',
  'logo design,',
  'custom layout,',
  'frontend implementation,',
  'integration of custom or existing backend (CMS),',
  'preparation of responsive mobile version,',
  'deployment of the finished website to the server,',
  'basic optimization for search engines (SEO),',
  'technical support and assistance during expansion,',
  'integration with additional modules and tools.'
];

export const technologies = [
  'PHP,',
  'Laravel,',
  'MySQL,',
  'ZendFramework,',
  'HTML5,',
  'CSS3,',
  'JavaScript,',
  'React,',
  'Vue.js,',
  'Drupal CMS,',
  'Headless CMS.',
];
