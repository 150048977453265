import React from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import ColoredBox from 'src/components/Sites/ServicesPages/shared/ColoredBox';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import {
  FadeInRight,
  FadeIn,
} from 'src/components/shared/Animations/Animations';
import useLng from "../../../../hooks/useLng";

const WhyItIsWorthContentWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;

  ${ParagraphInfo} {
    margin-bottom: 3.2rem;
  }

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const ParagraphTitle = styled.h3`
  margin-bottom: 3.2rem;
  line-height: ${({ big }) => (big ? 1.6 : 1.8)};
  font-size: ${({ big }) => (big ? '2.2rem' : '1.8rem')};

  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;

const StyledLabel = styled(Label)`
  margin-bottom: 10.5rem;

  @media screen and (max-width: 767px) {
    margin-bottom: 5rem;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  @media screen and (max-width: 1199px) {
    padding: 0;
  }
`;

const WhyItIsWorthSection = ({city, data}) => {
  const { t } = useLng()
  return (
      <Section>
        <Wrapper nonGrid>
          <FadeIn>
            <StyledLabel>
              {!!city ? t("why_c", {name: city}) : t("why")}
            </StyledLabel>
          </FadeIn>
        </Wrapper>
        <WhyItIsWorthContentWrapper mw>
          <StyledInnerWrapper pl nonGrid>
            <InnerBox>
              <FadeIn>
                <ParagraphTitle>
                  {t("why2")}
                </ParagraphTitle>
                <ParagraphInfo smallMedium>
                  {t("why3")}
                </ParagraphInfo>
              </FadeIn>
              <FadeIn>
                <ParagraphTitle>
                  {t("why4")}</ParagraphTitle>
                <ParagraphInfo smallMedium>
                  {t("why5")}
                </ParagraphInfo>
              </FadeIn>
            </InnerBox>
          </StyledInnerWrapper>
          <div>
            <InnerBox pl>
              <FadeIn>
                <ParagraphTitle>
                  {t("why6")}
                </ParagraphTitle>
                <ParagraphInfo smallMedium>
                  {t("why7")}
                </ParagraphInfo>
              </FadeIn>
            </InnerBox>
            <FadeInRight>
              <ColoredBox>
                <InnerBox>
                  <ParagraphTitle big>
                    {!!city ? t("why8_c", {name: city}) : t("why8")}
                  </ParagraphTitle>
                  <ParagraphInfo smallMedium mb="0 !important">
                    {t("why9")}
                  </ParagraphInfo>
                </InnerBox>
              </ColoredBox>
            </FadeInRight>
          </div>
        </WhyItIsWorthContentWrapper>
      </Section>
  );
}
export default WhyItIsWorthSection;
