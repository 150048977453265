import React from 'react';
import { MainSection as Section, Wrapper } from '../shared/ServicesPages';
import styled from 'styled-components';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import ColoredBox from 'src/components/Sites/ServicesPages/shared/ColoredBox';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import responsiveDesignIcon from 'src/assets/images/icons/responsive-design.svg';
import seoIcon from 'src/assets/images/icons/seo.svg';
import planningIcon from 'src/assets/images/icons/planning.svg';
import securityIcon from 'src/assets/images/icons/security.svg';
import {
  FadeIn,
  FadeInLeft,
} from 'src/components/shared/Animations/Animations';
import useLng from "../../../../hooks/useLng";

const StyledParagraphInfo = styled(ParagraphInfo)`
  font-size: ${({ small }) => (small ? '2rem' : '3rem')};
  line-height: 1.5;
  text-align: ${({ center }) => center && 'center'};
`;

const ServiceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5.5rem;

  @media screen and (max-width: 575px) {
    display: block;
  }
`;

const StyledInnerBox = styled(InnerBox)`
  padding-left: 5rem;
  max-width: 60%;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }

  @media screen and (max-width: 575px) {
    padding-left: 0;
  }
`;

const StyledImageWrapper = styled.div`
  margin: 0 auto 2rem;
  width: 11rem;
  @media screen and (max-width: 767px) {
    margin: 4rem 0;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const OurWebsitesStandOutSection = ({data}) => {
  const { t } = useLng()

  return (
    <Section>
      <Wrapper nonGrid>
        <FadeIn>
          <Label>{t("czym")}</Label>
        </FadeIn>
        <StyledParagraphInfo mb="4rem" small>
          <FadeIn>
            {t("czym_text")} </FadeIn>
        </StyledParagraphInfo>
      </Wrapper>
      <Wrapper nonGrid>
        <ServiceWrapper>
          <ColoredBox icon width="28rem" height="28rem">
            <StyledImageWrapper>
              <StyledImage
                alt="responsywność stron i aplikacji internetowych"
                src={responsiveDesignIcon}
              />
            </StyledImageWrapper>
            <ParagraphInfo title>
              {t("czym1")}
            </ParagraphInfo>
          </ColoredBox>
          <StyledInnerBox pl>
            <FadeIn up>
              <ParagraphInfo smallMedium bold mb="3.2rem">
                {t("czym1_2")}

              </ParagraphInfo>
              <ParagraphInfo smallMedium>
                {t("czym1_text")}
              </ParagraphInfo>
            </FadeIn>
          </StyledInnerBox>
        </ServiceWrapper>
        <ServiceWrapper>
          <ColoredBox transparent icon width="28rem" height="28rem">
            <StyledImageWrapper>
              <StyledImage
                alt="pozycjonowanie stron internetowych"
                src={seoIcon}
              />
            </StyledImageWrapper>
            <ParagraphInfo title>{t("czym2")}</ParagraphInfo>
          </ColoredBox>
          <StyledInnerBox pl>
            <FadeIn up>
              <ParagraphInfo smallMedium bold mb="3.2rem">
                {t("czym2_2")}
              </ParagraphInfo>
              <ParagraphInfo smallMedium>
                {t("czym2_text")}
              </ParagraphInfo>
            </FadeIn>
          </StyledInnerBox>
        </ServiceWrapper>
        <ServiceWrapper>
          <ColoredBox icon width="28rem" height="28rem">
            <StyledImageWrapper>
              <StyledImage
                alt="szybkość działania stron internetowych"
                src={planningIcon}
              />
            </StyledImageWrapper>
            <ParagraphInfo title>{t("czym3")}</ParagraphInfo>
          </ColoredBox>
          <StyledInnerBox pl>
            <FadeIn up>
              <ParagraphInfo smallMedium bold mb="3.2rem">
                {t("czym3_2")}
              </ParagraphInfo>
              <ParagraphInfo smallMedium>
                {t("czym3_text")}
              </ParagraphInfo>
            </FadeIn>
          </StyledInnerBox>
        </ServiceWrapper>
        <ServiceWrapper>
          <ColoredBox transparent icon width="28rem" height="28rem">
            <StyledImageWrapper>
              <StyledImage
                alt="bezpieczeństwo stron internetowych"
                src={securityIcon}
              />
            </StyledImageWrapper>
            <ParagraphInfo title>
              {t("czym4")}
            </ParagraphInfo>
          </ColoredBox>
          <StyledInnerBox pl>
            <FadeIn up>
              <ParagraphInfo smallMedium bold mb="3.2rem">
                {t("czym4_2")}
              </ParagraphInfo>
              <ParagraphInfo smallMedium>
                {t("czym4_text")}
              </ParagraphInfo>
            </FadeIn>
          </StyledInnerBox>
        </ServiceWrapper>
      </Wrapper>
    </Section>
  );
};

export default OurWebsitesStandOutSection;
