import React, { useContext } from 'react';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import List from 'src/components/shared/List/List';
import {typesOfWebsites, typesOfWebsitesEN} from 'src/components/Sites/ServicesPages/WebPages/data';
import styled from 'styled-components';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import { WebsitesPageContext } from 'src/pages/strony-internetowe';
import {useI18next} from "gatsby-plugin-react-i18next";
import   useLng  from "src/hooks/useLng"

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1199px) {
    display: grid;
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding: 0 20px;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  margin-bottom: 7rem;

  @media screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }
`;

const PagesWeMakeSection = ({city, data}) => {
const { t } = useLng()
const { language } = useI18next()
  const sliderImages = data?.filter(
    ({ name }) =>
      name === 'weranda family' ||
      name === 'rodzaje stron jakie wykonujemy zlobek',
  );

  return (
    <Section>
      <StyledWrapper pr>
        <MoveIn style={{ flexBasis: '50%' }}>
          <Slider
            containerHeight="clamp(25rem, 62vw, 57rem)"
            onlyDesktop
            slides={sliderImages}
          />
        </MoveIn>
        <InnerBox pl>
          <FadeIn>
            <StyledSubTitle>
              {!!city ? t("types_c", {name: city}) : t("types")}
            </StyledSubTitle>
          </FadeIn>
          <List items={language === "pl" ? typesOfWebsites : typesOfWebsitesEN} />
        </InnerBox>
      </StyledWrapper>
    </Section>
  );
};

export default PagesWeMakeSection;
